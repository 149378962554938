import React, { useState } from 'react';
import '../styles/layout/WorkplaceSafety.css'
const PrivacyPolicies = () => {


  return (
    <div className='policy'>
    <h1>Workplace Safety</h1>
    <ul>
      <li>
        <a href="/documents/Posh.pdf" target="_blank" rel="noopener noreferrer">
        POSH@WORK
        </a>
      </li>
      <li>
        <a href="/documents/Equal.pdf" target="_blank" rel="noopener noreferrer">
          Equal Opportunity Policy
        </a>
      </li>
    </ul>
  </div>
  );
}

export default PrivacyPolicies;
