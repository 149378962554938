import React from 'react'
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom'

const PageNotFound = () => {
  return (
    <div style={{backgroundColor: "#222", paddingTop: '150px', paddingBottom: '50px'}}>
    {/* <div className='serviceBanner'>
    <Container>
        <Row>
            <Col md={12}>
              <h1>Thank You</h1>
            </Col>
        </Row>
    </Container>
    </div> */}
    <Container>
    <Row className='text-center mt-5 mb-5'>
        <Col md={12}>
          <div className='pageNotFound'>
            <h1 className='text-white'>404</h1>
            <h4 className='text-white'>Page Not Found!</h4>
            <p className='text-white'>Let's go 
              <Link to="/"> home </Link> and try from there.
            </p>
          </div>
        </Col>
    </Row>
</Container>
</div>
  )
}

export default PageNotFound