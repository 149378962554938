import React, {useState} from 'react'
import '../styles/layout/About.css'
import { Container, Row, Col, Image, Modal } from 'react-bootstrap';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import ggSrinivas from '../assests/gg-srinivas.png'
import madduriSrikant from '../assests/madduri-srikant.png'
import sreekanthAnantharaman from '../assests/sreekanth-anantharaman.png'
import gururajaRao from '../assests/gururaja-rao.png'
import sureshWarrier from '../assests/suresh-warrier.png'
import tNedumaran from '../assests/t.nedumaran.png'

const AboutusTeam = () => {

// Modals Start
const [gg, setGg] = useState(false);
const [ms, setMs] = useState(false);
const [sa, setSa] = useState(false);
const [gr, setGr] = useState(false);
const [sw, setSw] = useState(false);
const [tn, setTn] = useState(false);
// Modals End

  return (
    <div className="our-team">
        <Container>
            <Row className="our-team-title">
                <Col md={12}>
                    <h2>Meet The Team</h2>
                </Col>
            </Row>
            <Row className="text-center">
                <Col md={4}>
                    <div className="innerBox">
                        <div className="teamImage">
                            <Image src={ggSrinivas} />
                        </div>
                        <div className="team-title">
                            <h3>GG Srinivas (aka GG)</h3>
                            <h4>Co-Founder & Chief Executive Officer</h4>
                        </div>
                        <div className="teamText">
                            <p>
                                <br />
                                GG Srinivas (aka GG) is a Co-founder and CEO at 
                                42 Card Solutions. He has 20 ...
                            </p>
                        </div>
                        <div className="teamSocial">
                            <span className="leftTeamSocial"> 
                                <a href="https://www.linkedin.com/in/ggsrinivas/" target="_blank"> 
                                    <LinkedInIcon style={{ backgroundColor: '#0077b7', color: '#fff'}} /> 
                                </a> 
                            </span>
                            <span className="rightTeamSocial"  onClick={() => setGg(true)} style={{cursor: 'pointer'}} > Read More </span>
                        </div>
                    </div>
                    <Modal
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={gg}
                        onHide={() => setGg(false)}
                        >
                        <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            <h1 className='font-weight-bolder'>GG Srinivas</h1>
                            <h6 className='fst-italic'>Co-founder and CEO</h6>
                        </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                            <strong>GG Srinivas (aka GG)</strong> is a Co-founder and CEO at 42 Card Solutions. He has 20 odd 
                            years of payment card experience of which 10 years were with Banks in India and 
                            10 years in a start-up he had co-founded. He is immensely proud at spotting a 
                            need in the market, putting a solution and a team together, more so the second 
                            time round. GG has a Bachelors in Technology from NIT, Warangal (1992) and a 
                            PGDM from IIM Bangalore (1997). He firmly believes that there are two worlds 
                            in India. Those who yawn at a credit card and those who never seem to get one. 
                            The thought of making a product for both these worlds – what an opportunity! GG 
                            loves all things technology, home schooled on Python and trying to apply it to 
                            arcane problems. Loves to think of his half marathons and wishing to do some 
                            more in the future. To quote GG “We have a fantastic team and it would be a 
                            fond hope to setup a world class analytics-based credit card program which can 
                            be used by, as our tagline says, <strong>Everyone, Everywhere and Everything.</strong> ”
                            </p>
                            <div>
                            <span > 
                                <a href="https://www.linkedin.com/in/ggsrinivas/" target="_blank"> 
                                    <LinkedInIcon style={{fontSize: '50px', backgroundColor: '#fff', color: '#0077b7'}} /> 
                                </a> 
                            </span>
                            </div>
                        </Modal.Body>
                    </Modal>
                </Col>
                <Col md={4}>
                    <div className="innerBox">
                        <div className="teamImage">
                            <Image src={madduriSrikant} />
                        </div>
                        <div className="team-title">
                            <h3>Madduri Srikant</h3>
                            <h4>Co-Founder & Chief Technology Officer</h4>
                        </div>
                        <div className="teamText">
                            <p>
                               
                                Madduri Srikant is a Co-founder and Chief Technology 
                                Officer of 42 Card Solutions. He is ...
                            </p>
                        </div>
                        <div className="teamSocial">
                            <span className="leftTeamSocial"> 
                                <a href="https://www.linkedin.com/in/maddurisrikant/"  target="_blank"> 
                                    <LinkedInIcon style={{ backgroundColor: '#0077b7', color: '#fff'}}  /> 
                                </a> 
                            </span>
                            <span className="rightTeamSocial"  onClick={() => setMs(true)} style={{cursor: 'pointer'}} > Read More </span>
                        </div>
                    </div>
                    <Modal
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={ms}
                        onHide={() => setMs(false)}
                        >
                        <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            <h1 className='font-weight-bolder'>Madduri Srikant</h1>
                            <h6 className='fst-italic'>Co-founder and Chief Technology Officer</h6>
                        </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                            <strong>Madduri Srikant</strong> is a Co-founder and Chief Technology Officer of 42 Card Solutions. 
                            He is accountable for building a scalable, responsive, and resilient technology 
                            platform to serve clients and support management. Madduri is an electrical engineering 
                            graduate from IIT Kanpur. He completed his PGDM from IIM, Bangalore, and entered the 
                            banking sector. He worked in fintech for more than two decades in areas like core 
                            banking, payments, and regulatory reporting with companies like Citicorp, HCL, 
                            Morgan Stanley, and Tech Mahindra. From 2001 to 2005, he led the development and 
                            implementation of a Real-Time Gross Settlement System. He is looking forward to 
                            using his technology interests and leadership skills to create the best possible 
                            experience for our cardholders. Apart from work, you would see Madduri playing 
                            scrabble, reading books, and indulging in urban exploration in his me-time.   
                            </p>
                            <div>
                            <span > 
                                <a href="https://www.linkedin.com/in/maddurisrikant/" target="_blank"> 
                                    <LinkedInIcon style={{fontSize: '50px', backgroundColor: '#fff', color: '#0077b7'}} /> 
                                </a> 
                            </span>
                            </div>
                        </Modal.Body>
                    </Modal>
                </Col>
                <Col md={4}>
                    <div className="innerBox">
                        <div className="teamImage">
                            <Image src={sreekanthAnantharaman} />
                        </div>
                        <div className="team-title">
                            <h3>Sreekanth Anantharaman</h3>
                            <h4>Co-Founder & Chief Risk Officer</h4>
                        </div>
                        <div className="teamText">
                            <p>
                                <br />
                                Sreekanth Anantharaman is a Co-founder and the 
                                Chief Risk Officer of 42 Card Solutions...
                            </p>
                        </div>
                        <div className="teamSocial">
                            <span className="leftTeamSocial"> 
                                <a href="https://www.linkedin.com/in/sreekanth-anantharaman-7a46351/"  target="_blank"> 
                                    <LinkedInIcon style={{ backgroundColor: '#0077b7', color: '#fff'}}  /> 
                                </a> 
                            </span>
                            <span className="rightTeamSocial"  onClick={() => setSa(true)} style={{cursor: 'pointer'}} > Read More </span>
                        </div>
                    </div>
                    <Modal
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={sa}
                        onHide={() => setSa(false)}
                        >
                        <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            <h1 className='font-weight-bolder'>Sreekanth Anantharaman</h1>
                            <h6 className='fst-italic'>Co-founder and Chief Risk Officer</h6>
                        </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                            <strong>Sreekanth Anantharaman</strong> is a Co-founder and Chief risk Officer of 42 
                            Card Solutions. He ensures prudent underwriting standards and effective portfolio 
                            management to build a high-quality asset book. Sreekanth earned his Bachelor's 
                            Degree in Commerce from Chennai and a PG Diploma in Management from IIM, Ahmedabad. 
                            He has worked predominantly in risk management with organisations like Bank of America, 
                            ABN Amro Bank and American Express in India, Asia-pacific, and the Middle East within 
                            his 30 years long career. During his tenure, cardholders' spending increased 4x, and 
                            net income increased 7x, significantly improving asset quality metrics. Sreekanth finds 
                            analyzing data and patterns to predict future customer behaviour a fascinating pursuit. 
                            He plans to use his substantial experience in financial services to assist clients of 
                            42 Card Solutions in improving customer experience and portfolio profitability. 
                            Sreekanth is an associate member of the Institute of Cost & Works Accountants of India. 
                            He loves cycling, playing golf, going on long hikes, and cooking during his breaks from work.   
                            </p>
                            <div>
                            <span > 
                                <a href="https://www.linkedin.com/in/sreekanth-anantharaman-7a46351/" target="_blank"> 
                                    <LinkedInIcon style={{fontSize: '50px', backgroundColor: '#fff', color: '#0077b7'}} /> 
                                </a> 
                            </span>
                            </div>
                        </Modal.Body>
                    </Modal>
                </Col>
            </Row>
            <Row className="text-center">
            <Col md={4}>
                    <div className="innerBox">
                        <div className="teamImage">
                            <Image src={gururajaRao} />
                        </div>
                        <div className="team-title">
                            <h3>Gururaja Rao</h3>
                            <h4>Co-Founder & Head Operations​​</h4>
                        </div>
                        <div className="teamText">
                            <p>
                                <br />
                                Gururaja Rao is a Co-founder & Head of Operations at 
                                42 Card Solutions. He is a com....
                            </p>
                        </div>
                        <div className="teamSocial">
                            <span className="leftTeamSocial"> 
                                <a href="https://www.linkedin.com/in/gururaja-rao-3bb90315/"  target="_blank"> 
                                    <LinkedInIcon style={{ backgroundColor: '#0077b7', color: '#fff'}}  /> 
                                </a> 
                            </span>
                            <span className="rightTeamSocial"  onClick={() => setGr(true)} style={{cursor: 'pointer'}} > Read More </span>
                        </div>
                    </div>
                    <Modal
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={gr}
                        onHide={() => setGr(false)}
                        >
                        <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            <h1 className='font-weight-bolder'>Gururaja Rao</h1>
                            <h6 className='fst-italic'>Co-founder and Head Operations​​</h6>
                        </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                            <strong>Gururaja Rao</strong> is a Co-founder & Head of Operations at 42 Card Solutions. 
                            He is a Graduate in Commerce and a Graduate in General Laws. He is an Associate Member 
                            of the Indian institute of bankers and finance (IIBF) and a Fellow Member of the 
                            Institute of Company Secretaries of India. Gururaja Rao is a career banker having 
                            worked with leading banks in the public and private sector in India. Thirty-five years 
                            of experience in all aspects of banking has made him strong enough to understand the 
                            banking sector very well. His experience has helped him set up, manage and grow banking 
                            franchises of large banks across cycles. He admires and gets inspired by people with a 
                            positive attitude despite having multiple challenges in their way. He loves doing yoga 
                            and aerobic exercises in his spare time to keep his mind and body calm and focussed. 
                            His vision for 42 Card Solution is to see the company as a top credit card issuer 
                            with one of the lowest default rates and the highest return on assets (ROA) in 
                            the near future.    
                            </p>
                            <div>
                            <span > 
                                <a href="https://www.linkedin.com/in/gururaja-rao-3bb90315/" target="_blank"> 
                                    <LinkedInIcon style={{fontSize: '50px', backgroundColor: '#fff', color: '#0077b7'}} /> 
                                </a> 
                            </span>
                            </div>
                        </Modal.Body>
                    </Modal>
                </Col>
                <Col md={4}>
                    <div className="innerBox">
                        <div className="teamImage">
                            <Image src={sureshWarrier} />
                        </div>
                        <div className="team-title">
                            <h3>Suresh-Warrier</h3>
                            <h4>Co-Founder & Head of Partner Relations</h4>
                        </div>
                        <div className="teamText">
                            <p>
                                Suresh Warrier is a Co-founder and Head of Partner Relations at
                                42 Card Solutions.
                            </p>
                        </div>
                        <div className="teamSocial">
                            <span className="leftTeamSocial"> 
                                <a href="https://www.linkedin.com/in/suresh-warrier-0023405/"  target="_blank"> 
                                    <LinkedInIcon style={{ backgroundColor: '#0077b7', color: '#fff'}}  /> 
                                </a> 
                            </span>
                            <span className="rightTeamSocial"  onClick={() => setSw(true)} style={{cursor: 'pointer'}} > Read More </span>
                        </div>
                    </div>
                    <Modal
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={sw}
                        onHide={() => setSw(false)}
                        >
                        <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            <h1 className='font-weight-bolder'>Suresh Warrier</h1>
                            <h6 className='fst-italic'>Co-founder and Head Partner Relations​</h6>
                        </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                            <strong>Suresh Warrier</strong> is a Co-founder and Head of Partner Relations at 
                            42 Card Solutions. He has a Bachelor’s Degree in Science and a Post-Graduate 
                            Degree in Marketing Management from the Jamnalal Bajaj Institute of Management 
                            Studies, Mumbai (JBIMS). Suresh is a certified member of the Indian Institute of 
                            Bankers & Finance (IIBF). With over 30 years of experience in the financial 
                            services & banking sector in India and abroad, Suresh has held leadership 
                            positions for various organisational functions, including at the Branch, 
                            Regional, and Corporate offices. He has set up and led operations for over 
                            six years for a leading Indian private sector bank in the Middle East. 
                            Suresh is passionate about movies, music, reading, and wildlife 
                            (not necessarily in that order). Suresh plans to use his experience across 
                            multiple geographies and organizations to help 42 Card Solutions acquire, 
                            build and strengthen relationships with various stakeholders.
                            </p>
                            <div>
                            <span > 
                                <a href="https://www.linkedin.com/in/suresh-warrier-0023405/" target="_blank"> 
                                    <LinkedInIcon style={{fontSize: '50px', backgroundColor: '#fff', color: '#0077b7'}} /> 
                                </a> 
                            </span>
                            </div>
                        </Modal.Body>
                    </Modal>
                </Col>
                <Col md={4}>
                    <div className="innerBox">
                        <div className="teamImage">
                            <Image src={tNedumaran} />
                        </div>
                        <div className="team-title">
                            <h3>T.Nedumaran</h3>
                            <h4>Head - Compliance</h4>
                        </div>
                        <div className="teamText">
                            <p>
                                <br />
                                T.Nedumaran is a Head of Compliance at 
                                42 Card Solutions and is involved .....
                            </p>
                        </div>
                        <div className="teamSocial">
                            <span className="leftTeamSocial"> 
                                <a href="https://www.linkedin.com/in/nedu-maran-6a581836/"  target="_blank"> 
                                    <LinkedInIcon style={{ backgroundColor: '#0077b7', color: '#fff'}}  /> 
                                </a> 
                            </span>
                            <span className="rightTeamSocial"  onClick={() => setTn(true)} style={{cursor: 'pointer'}} > Read More </span>
                        </div>
                    </div>
                    <Modal
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                        show={tn}
                        onHide={() => setTn(false)}
                        >
                        <Modal.Header closeButton>
                        <Modal.Title id="example-modal-sizes-title-sm">
                            <h1 className='font-weight-bolder'>T.Nedumaran</h1>
                            <h6 className='fst-italic'>Head - Compliance​</h6>
                        </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>
                            <strong>T.Nedumaran</strong> is a Head of Compliance at 42 Card Solutions and 
                            is involved in streamlining activities under Compliance and Finance. A gold 
                            medallist from Madurai University, Nedumaran is also a qualified Chartered 
                            Accountant and a Certified Associate of the Indian Institute of Bankers (CAIIB) 
                            from the Indian Institute of Banking and Finance (IIBF). He has over 32 years 
                            of experience in different aspects of banking, having worked both in India and 
                            in two overseas geographies. He is well versed in Credit, Compliance, 
                            Operations, and Finance.
                            </p>
                            <p>Nedumaran is a team player with a positive attitude, constantly striving for the 
                                betterment of his team and organization. He actively collaborates with the 
                                management for effective strategy building and is good at putting up process 
                                flow SOPs. He aspires to make 42 Card Solutions one of the prominent card 
                                management entities with wide acceptance.
                            </p>
                            <div>
                            <span > 
                                <a href="https://www.linkedin.com/in/nedu-maran-6a581836/" target="_blank"> 
                                    <LinkedInIcon style={{fontSize: '50px', backgroundColor: '#fff', color: '#0077b7'}} /> 
                                </a> 
                            </span>
                            </div>
                        </Modal.Body>
                    </Modal>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default AboutusTeam